import React from 'react';
import { ResponsiveImageType, StructuredTextDocument } from 'react-datocms';
import Color, { colors } from '../../../primitives/colors';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import StyledLink from '../../../primitives/styled-link';
import AspectRatio from '../../../primitives/aspect-ratio';
import DatoAsset from '../../../primitives/asset';
import Background from '../../../primitives/backgrounds';
import RightCaret from './right-caret.svg';
import Link, { getLink, LinkWithTitle } from '../../../primitives/link';

interface SolutionsCardProps {
    title: string
    richText: StructuredTextDocument
    quote: string
    image: ResponsiveImageType
    link: LinkWithTitle
    noAspect?: boolean
}

const SolutionsCard = ({
    title, richText, quote, image, link, noAspect,
}: SolutionsCardProps): JSX.Element => (
    <Color
        backgroundColor="shadesWhite"
        css={{
            borderRadius: borderRadius.large,
            width: '100%',
            boxShadow: '0 34px 54px 0 rgba(0, 0, 0, 0.15)',
        }}
        role="group"
    >
        <div css={{
            display: 'flex',
            height: '100%',
            [breakpoints.mb]: {
                flexDirection: 'column-reverse',
                justifyContent: 'flex-end',
            },
        }}
        >
            <div css={{
                padding: '28px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                [breakpoints.mb]: {
                    padding: '18px',
                },
            }}
            >
                <Typography
                    fontSize={{
                        dt: 2429,
                        mb: 1627,
                    }}
                    css={{
                        [breakpoints.mb]: {
                            maxWidth: '60%',
                        },
                    }}
                    color="primaryOrange"
                >
                    {title}
                </Typography>
                <div css={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Typography
                        fontSize={{
                            dt: 1627,
                            tb: 1420,
                            mb: 1226,
                        }}
                        css={{
                            paddingTop: '12px',
                            paddingBottom: '26px',
                            [breakpoints.mb]: {
                                flexGrow: 1,
                                height: '100%',
                                paddingTop: '20px',
                                paddingBottom: '18px',
                            },
                        }}
                        color="primaryDarkPurple"
                    >
                        <CustomStructuredTextRenderer data={richText} />
                    </Typography>
                    <div>
                        <StyledLink to={getLink(link?.[0]?.link)}>
                            {link?.[0]?.title}
                        </StyledLink>
                    </div>
                </div>
            </div>
            <div css={{
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
                borderTopRightRadius: borderRadius.large,
                borderBottomRightRadius: borderRadius.large,
                transform: 'translateZ(0px)',
                [breakpoints.mb]: {
                    borderBottomRightRadius: '0',
                    borderTopLeftRadius: borderRadius.large,
                },
            }}
            >
                <AspectRatio
                    css={{
                        height: '100%',
                        backgroundColor: colors.grey200,
                        borderTopRightRadius: borderRadius.large,
                        borderBottomRightRadius: borderRadius.large,
                        [breakpoints.mb]: {
                            borderBottomRightRadius: '0',
                            borderTopLeftRadius: borderRadius.large,
                        },
                    }}
                    breakpoints={{
                        dt: noAspect ? 'none' : {
                            width: 343,
                            height: 305,
                        },
                        mb: noAspect ? 'none' : {
                            width: 258,
                            height: 180,
                        },
                    }}
                >
                    <DatoAsset
                        css={{
                            height: '100%',
                            width: '100%',
                            borderTopRightRadius: borderRadius.large,
                            borderBottomRightRadius: borderRadius.large,
                            overflow: 'hidden',
                            pointerEvents: 'none',
                            transition: '10s',
                            transform: 'scale(1.001)',
                            '[role="group"]:hover &': {
                                transform: 'scale(1.1)',
                            },
                            [breakpoints.mb]: {
                                borderBottomRightRadius: '0',
                                borderTopLeftRadius: borderRadius.large,
                            },
                        }}
                        asset={image}
                    />
                </AspectRatio>
                <Background
                    breakpoints={{
                        dt: {
                            gradient: 'blackAlpha',
                        },
                    }}
                    css={{
                        color: colors.shadesWhite,
                        position: 'absolute',
                        width: '100%',
                        bottom: '0',
                        padding: '28px 22px',
                        borderBottomRightRadius: borderRadius.large,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '10px',
                        [breakpoints.mb]: {
                            borderBottomRightRadius: '0',
                            padding: '16px 18px',
                        },
                    }}
                >
                    <Typography
                        as={Link}
                        to={getLink(link?.[0]?.link)}
                        color="shadesWhite"
                        fontSize={{
                            dt: 1627,
                            tb: 1420,
                            mb: 1226,
                        }}
                        override
                        fontWeight="light"
                    >
                        <CustomStructuredTextRenderer
                            css={{
                                'b, strong': {
                                    color: colors.shadesWhite,
                                    fontWeight: 'bold',
                                },
                            }}
                            data={quote}
                        />
                    </Typography>
                    <RightCaret css={{ flex: 'none' }} />
                </Background>
            </div>
        </div>
    </Color>
);

export default SolutionsCard;
